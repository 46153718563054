import { Defaults } from "./defaults";
$(() => {
    Defaults.init();
    $('#view').on('click', () => {
        $("#dor-top-menu").animate({
            left: -260,
        }, 500);
        $("body").removeClass("dor-mobile");
        $('body > main .dor-overlay').remove();
    });
});
import "expose-loader?exposes[]=$&exposes[]=jQuery!jquery";
import "expose-loader?exposes[]=JSZip!jszip";
import "expose-loader?exposes[]=App!./modules/app";
