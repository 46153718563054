import { tns } from 'tiny-slider';
export class CeremoniasDeTe {
    constructor() {
        var slider = tns({
            container: '.tiny-slider',
            items: 3,
            slideBy: 'page',
            autoplay: true
        });
    }
}
