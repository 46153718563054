import { tns } from 'tiny-slider';
export class TeHerbalYFrutal {
    constructor() {
        $.each($(".slider"), (i, e) => {
            tns({
                container: e,
                items: 1,
                slideBy: 'page',
                autoplay: true
            });
        });
    }
}
