import { tns } from 'tiny-slider';
import LocomotiveScroll from 'locomotive-scroll';
import "../../../css/locomotive-scroll.css";
export class Inicio {
    constructor() {
        var slider = tns({
            container: '.tiny-slider',
            items: 1,
            slideBy: 'page',
            autoplay: true,
            controls: false,
            nav: false,
            autoplayButtonOutput: false,
            autoplayTimeout: (15) * 1000
        });
        Inicio.scroller = new LocomotiveScroll({
            el: document.querySelector('main'),
            smooth: true,
            smartphone: {
                smooth: true
            },
            scrollFromAnywhere: true
        });
    }
    static scrollerUpdate() {
        this.scroller.update();
    }
}
