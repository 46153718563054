import { tns } from 'tiny-slider';
export class Eventos {
    constructor() {
        $.each($(".slider"), (i, e) => {
            tns({
                container: e,
                items: 2,
                slideBy: 'page',
                autoplay: true
            });
        });
        $("#nanogallery2").nanogallery2({
            // DISPLAY ANIMATION
            thumbnailL1DisplayTransition: 'slideUp',
            thumbnailDisplayTransition: 'slideDown',
            thumbnailDisplayTransitionDuration: 1500,
            thumbnailDisplayInterval: 30,
            thumbnailHeight: 'auto',
            thumbnailWidth: 400,
            thumbnailAlignment: 'center',
        });
    }
}
